import React from "react";
import HCarosel from "../Component/Carosel/Carosel";
import MissionVission from "./MV/MV";
import HS from "./HS/HS";
import Events from "./Events/Events";
import ServicesCarosel from "../Component/Carosel/Services_Carosel";
import CourseOffering from "./Course_Offering/Course_Offering";
import StudyWithUs from "./Study_with_us/Study_with_us";
import Json_Data from "../Assets/JSON/MV.json";
// import CardPeople from "../Component/Cards/CardPeople";
// import CardSocialMedia from "../Component/Cards/CardSocialMedia";

const Home = () => {
  // const Events_Data = [
  //   {
  //     tittle: "DR. B. R. Ambedkar",
  //     designation: "President",
  //     image: "",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
  //     date: "17 July 2023",
  //   },
  //   {
  //     tittle: "DR. B. R. Ambedkar",
  //     designation: "President",
  //     image: "",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
  //     date: "17 July 2023",
  //   },
  //   {
  //     tittle: "DR. B. R. Ambedkar",
  //     designation: "President",
  //     image: "",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
  //     date: "17 July 2023",
  //   },
  // ];
  const PBData = {
    PB: [
      {
        tittle: "DR. B. R. Ambedkar",
        designation: "President",
        image: "",
        description:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        designation: "President",
        image: "",
        description:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        designation: "President",
        image: "",
        description:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        designation: "President",
        image: "",
        description:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
    ],
  };

  const MV_Data = {
    Mission: {
      tittle: "Mission",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/MV/mission.jpg",
      description:
        "To install a zeal and passion to educate, organize and struggle. To ensure bright future with confidence and sense of dignity. To inculcate moral values and social responsibilities.",
    },
    Vission: {
      tittle: "Vision",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/MV/vision.jpg",
      description:
        "To empower the socially, economically, educationally backward sections of the society through education, training, and self- employment.",
    },
  };

  const HSData = {
    tittle: "How we Started",
    image:
      "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/HS/HSImage.jpg",
    description:
      "Dr.B.R. Ambedkar Educational Institutions was started by Late. Sri G Venkataswamy Garu, former Union Minister, Govt. of India in the year 1973. These institutions are inaugurated by Sri V.V. Giri, by then Hon. President of India. Dr.B.R. Ambedkar Educational Institutions is nestled up throughout a 4 acre land in Baghlingampally, Hyderabad. Being in the heart of the city, it boasts of a huge campus, excellent infrastructure facilities and dedicated management and staff. The institutions group includes High School, Junior College, Degree College, Post-Graduate College, Law College and MBA College. Approximately, 3000 students in total are presently studying in the institutions. Apart from the academics, Dr. B.R. Ambedkar educational institutions excels in extra curricular activities too. We have one of the best National Cadet Corps (NCC) team, 3 NSS units. We have partnered with the esteemed Spotlight Cricket Academy which provides excellent cricket coaching for the age group 6 to 18 years.Dr.B.R. Ambedkar Degree College was started in the year 1973. It was granted Aid in the year 1977. Around 1100 students are studying in the degree college which offers courses including B.Com (general), B.com (computers), BSc (MPC), BSc (computers), B.Sc (electronics), BBA, BA (Journalism) and BA (EPP).",
  };

  PBData.PB[0].image = require("../Assets/Images/Rectangle 40.png");
  PBData.PB[1].image = require("../Assets/Images/Rectangle 42.png");
  PBData.PB[2].image = require("../Assets/Images/Rectangle 44.png");
  PBData.PB[3].image = require("../Assets/Images/Rectangle 44.png");

  return (
    <section className="home-container">
      <HCarosel />
      <StudyWithUs />
      <MissionVission MV_DATA={MV_Data} />
      <CourseOffering />
      <HS HS_DATA={HSData} />
      <ServicesCarosel />
      {/* <Events
        Event_DATA={Json_Data?.HomePage_Details?.Events_Activities?.slice(0, 4)}
        title="News and Events"
      /> */}
      {/* <CardPeople getdata={PBData} />
      <CardSocialMedia getdata={PBData} /> */}
    </section>
  );
};
export default Home;
